//**********************************************************
// Personal Info
//**********************************************************

@import '../../../scss/import';

.personal-info {
	position: relative;
	padding: 70px 0;
	min-height: calc(100vh - 60px);

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 36px 0;
	}

	// ##################################################

	&__header {
		display: grid;
		margin: 0 auto 64px;
		max-width: 580px;
		grid-auto-flow: column;
		grid-gap: 18px;
		align-items: center;
		color: var(--color-blue);

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 14px;
			line-height: normal;
			grid-gap: 12px;
			margin: 0 auto 46px;
			grid-auto-flow: row;
			grid-template-columns: 35% 1fr 46%;
			max-width: 340px;
		}

		// ##################################################

		img {
			object-fit: contain;
			max-width: 50px;
			max-height: 90px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: 36px;
				max-height: 66px;
			}
		}

		strong {
			// Tablet L and up
			@media #{$breakpoint-md-up} {
				font-size: 24px;
			}
		}
	}

	// ##################################################

	&__form {
		// ##################################################

		&-mandatory-text {
			color: var(--color-sky-blue);
			font-weight: 300;
		}

		// ##################################################

		&-inner {
			display: grid;
			margin: 0 auto;
			max-width: 734px;
			grid-gap: 30px 48px;
			grid-template-columns: repeat(2, minmax(0, 1fr));

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				grid-gap: 30px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				grid-gap: 20px;
				grid-template-columns: 1fr;
			}
		}

		// ##################################################

		&-item {
			&.span-2 {
				grid-column: span 2;

				// Mobile
				@media #{$breakpoint-xs-only} {
					grid-column: span 1;
				}
			}
		}

		&-submit {
			text-align: center;
			margin-top: 14px;
		}
	}

	// ##################################################

	.checkbox {
		font-size: 14px;
		line-height: 1.3;
		font-weight: 300;
	}

	// ##################################################

	.radiobox-inline {
		.radio-button {
			font-weight: 400;

			// ##################################################

			&:not(:last-child) {
				margin-right: 21px;

				// Mobile
				@media #{$breakpoint-xs-only} {
					margin-right: 1.1vh;
				}
			}
		}
	}
}
