// ##################################################
// Colors
// ##################################################
:root {
	--color-white: #fff;
	--color-black: #000;
	--color-text: #{$cgp_text};
	--color-blue: #{$cgp_blue};
	--color-blue-dark: #{$cgp_blue_dark};
	--color-blue-1: #{$cgp_blue_1};
	--color-sky-blue: #{$cgp_sky_blue};
	--color-red: #{$cgp_red};
}
