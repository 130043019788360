//**********************************************************
// Success Screen
//**********************************************************

@import '../../../scss/import';

.success-screen {
	position: relative;
	padding: 40px 0 20px;
	min-height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 4vh 0 2vh;
		min-height: calc(100vh - 140px);
	}

	// ##################################################

	&__title {
		margin-bottom: 40px;
		opacity: 0;
		animation: animate-text 2.2s cubic-bezier(0, 0, 0, 0.98) forwards;

		// Mobile
		@media #{$breakpoint-xs-only} {
			display: block;
			margin: 0 auto 4vh;
			max-width: 300px;
		}
	}

	// ##################################################

	&__key {
		margin-bottom: 80px;
		min-height: 320px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: column;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 8vh;
			min-height: 200px;
		}

		// ##################################################

		&-title {
			opacity: 0;
			position: relative;
			top: 20px;
			animation: animate-text 2.2s cubic-bezier(0, 0, 0, 0.98) forwards;
		}

		// ##################################################

		&-image {
			display: inline-block;
			max-width: 80px;
			margin-bottom: 20px;
			position: absolute;
			left: 50%;
			top: 52%;
			transform: translate(-50%, -50%);
			animation: animate-key 2.2s cubic-bezier(0, 0, 0, 0.98) forwards;

			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: 55px;
				top: 60%;
				animation: animate-key-mobile 2.2s cubic-bezier(0, 0, 0, 0.98) forwards;
			}

			// ##################################################

			img {
				object-fit: contain;
			}
		}
	}

	// ##################################################

	&__text {
		opacity: 0;
		max-width: 720px;
		animation: animate-text 2.2s cubic-bezier(0, 0, 0, 0.98) forwards;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 284px;
		}
	}

	// ##################################################

	&__loader {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		pointer-events: none;

		// ##################################################

		&-circle {
			position: absolute;
			top: 50%;
			width: 356px;
			height: 354px;
			left: 50%;
			transform: translate(-50%, -50%);
			transform-origin: center center;

			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 276px;
				height: 276px;
			}

			.animate-loader & {
				animation: animate-circle 2.2s cubic-bezier(0, 0, 0, 0.98) forwards;
			}
		}
	}
}

@keyframes animate-circle {
	0% {
		opacity: 1;
		transform: translate(-50%, 55vh) rotate(360deg) scale(1);
	}

	33% {
		opacity: 1;
		transform: translate(-50%, -50%) rotate(0);
	}

	60% {
		opacity: 1;
		transform: translate(-50%, -50%) rotate(0) scale(1);
	}

	80% {
		opacity: 1;
		transform: translate(-50%, -50%) rotate(360deg) scale(1.8);
	}

	90% {
		opacity: 1;
		transform: translate(-50%, -50%) rotate(360deg) scale(1.8);
	}

	100% {
		opacity: 0;
		transform: translate(-50%, -50%) rotate(360deg) scale(1.8);
	}
}

@keyframes animate-key {
	0% {
		transform: translate(-50%, -50%) scale(1);
	}

	60% {
		transform: translate(-50%, -50%) scale(1);
	}

	80% {
		transform: translate(-50%, -50%) scale(1.8);
	}

	90% {
		transform: translate(-50%, -50%) scale(1.8);
	}

	100% {
		transform: translate(-50%, -75%) scale(1.8);
	}
}

@keyframes animate-key-mobile {
	0% {
		top: 60%;
		transform: translate(-50%, -50%) scale(1);
	}

	60% {
		transform: translate(-50%, -50%) scale(1);
	}

	80% {
		transform: translate(-50%, -50%) scale(1.6);
	}

	90% {
		top: 60%;
		transform: translate(-50%, -50%) scale(1.6);
	}

	100% {
		top: 48%;
		transform: translate(-50%, -80%) scale(1.6);
	}
}

@keyframes animate-text {
	0% {
		opacity: 0;
	}

	90% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
