//**********************************************************
// Failure Screen
//**********************************************************

@import '../../../scss/import';

.failure-screen {
	position: relative;
	padding: 40px 0;
	min-height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 4vh 0 2vh;
		min-height: calc(100vh - 140px);
	}

	// ##################################################

	&__title {
		margin-bottom: 12px;
	}

	// ##################################################

	&__text {
		max-width: 660px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 400px;
		}
	}

	// ##################################################

	&__image {
		position: relative;
		left: -20px;
		max-width: 850px;
		margin: 60px auto 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			left: 0;
			margin-top: 6vh;
			max-width: 50vh;
		}
	}
}
