//**********************************************************
// INPUT FIELD
//**********************************************************

@import '../../../scss/import';

.input-field {
	width: 100%;
	position: relative;
	text-align: left;
	@include placeholder(#a8a8a8);

	// Tablet P and up
	@media #{$breakpoint-sm-up} {
		font-size: 18px;
		line-height: 1.1;
	}

	// ##################################################

	&__inner {
		position: relative;
	}

	// ##################################################

	&__label {
		margin-bottom: 4px;
	}

	// ##################################################

	&__input {
		width: 100%;
		height: 40px;
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
		background-color: var(--color-white);
		padding: 0 12px;
		border-radius: 3px;
		color: var(--color-blue-1);
		font-weight: 700;
		border: solid 2px rgba($cgp_blue_1, 0.14);
		transition: border 0.3s ease, color 0.3s ease;

		&:focus {
			border-color: var(--color-sky-blue);
		}
	}

	// ##################################################

	textarea {
		resize: none;
	}

	// ##################################################

	&.is-disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.input-field__input {
			color: var(--color-red);
			border-color: var(--color-red);
		}
	}
}
