//**********************************************************
// Before After Screen
//**********************************************************

@import '../../../scss/import';

.before-after-screen {
	position: relative;
	padding: 40px 0 20px;
	min-height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	overflow: hidden;
	z-index: #{$zindex_modal};
	@extend %body_background;

	// ##################################################

	&__content {
		position: relative;
		z-index: 3;
	}

	// ##################################################

	&__title {
		position: relative;
		font-size: 7.6vh;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 4.4vh !important;
		}
	}

	// ##################################################

	&__text {
		font-weight: 700 !important;
		font-size: 3.3vh;
		margin-top: 2vh;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 2.4vh !important;
			margin-top: 1.6vh;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 240px;
			margin: 10px auto 0;
		}
	}

	&--after {
		.before-after-screen__title {
			// Tablet L and up
			@media #{$breakpoint-md-up} {
				font-size: 6vh;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				font-size: 2.6vh;
				line-height: 1.2;
			}
		}

		.before-after-screen__text {
			// Tablet L and up
			@media #{$breakpoint-md-up} {
				font-size: 3vh;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				font-size: 1.6vh;
			}
		}
	}
}
