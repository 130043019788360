//*******************************************************************************
// WEBFONTS : Roboto Condensed
//*******************************************************************************
@font-face {
	font-family: 'Roboto Condensed';
	src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url('../fonts/RobotoCondensed-Light.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url('../fonts/RobotoCondensed-Regular.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url('../fonts/RobotoCondensed-Bold.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
