// ##################################################
// Button
// ##################################################

@import '../../../scss/import';

// Style
.btn {
	cursor: pointer;
	padding: 4px 30px;
	min-width: 208px;
	min-height: 40px;
	background-color: var(--color-sky-blue);
	color: var(--color-white);
	box-shadow: 3px 3px 0 0 rgba(151, 189, 210, 0.6);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 0;
	border-radius: 0;
	font-size: 25px;
	font-weight: 700;
	letter-spacing: 0.25px;
	transition: background-color 0.25s ease-in, box-shadow 0.25s ease-in, color 0.3s ease;
	text-transform: uppercase;
	font-family: inherit;
	line-height: 1;

	// Hover/Focus
	&:hover,
	&:focus {
		background-color: var(--color-blue-dark);
	}

	// ##################################################

	// Button text
	.btn__text {
		display: block;
	}
}
