// **************************************************
// Birth Date
// **************************************************

@import '../../../scss/import';

.birth-date {
	display: block;
	margin: 0 auto 20px;
	max-width: 600px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		max-width: 100%;
	}

	// ##################################################

	&__input {
		display: grid;
		grid-gap: 16px;
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	// ##################################################

	.input-field {
		margin-bottom: 0;

		// ##################################################

		&__input {
			text-align: center;
			height: 35px;
		}
	}

	// ##################################################

	&__validation-message {
		display: none;
		color: var(--color-red);
		margin-top: 8px;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.birth-date__validation-message {
			display: block;
		}

		// stylelint-disable-next-line
		.input-field__input {
			color: var(--color-red);
			border-color: var(--color-red);
		}
	}
}
