// **************************************************
// Footer
// **************************************************

@import '../../../scss/import';

.site-footer {
	width: 100%;
	padding: 16px 0;
	font-size: 16px;
	font-weight: 700;
	color: var(--color-blue);

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 1vh 0;
		font-size: 2vh;
	}

	// ##################################################

	&__nav {
		// ##################################################

		ul {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		// ##################################################

		li {
			display: inline-flex;
			margin: 0 16px;

			// Smaller screen
			@media (max-width: 400px) {
				margin: 0 6px;
			}
		}

		// ##################################################

		a {
			display: inline-block;
			position: relative;
			@extend %hover_lines;

			&::before,
			&::after {
				height: 2px;
				background-color: currentColor;
			}
		}
	}
}
