//**********************************************************
// Loader screen
//**********************************************************

@import '../../../scss/import';

.loader-screen {
	position: fixed;
	height: 100%;
	width: 100%;
	overflow: hidden;
	z-index: #{$zindex_modal};
	@extend %body_background;

	// ##################################################

	&__text {
		position: relative;
		z-index: 3;
	}

	// ##################################################

	&__circles {
		pointer-events: none;

		&-image {
			position: relative;
		}

		&-outer {
			width: 1460px;
			height: 1460px;
			left: 52%;
			top: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
		}

		img {
			object-fit: contain;
		}
	}
}
