// **************************************************
// Device Slider
// **************************************************

@import '../../../scss/import';

.device-slider {
	max-width: 700px;
	height: 490px;
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 3;

	// Tablet L and down
	@media #{$breakpoint-md-down} {
		max-width: 560px;
	}

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		max-width: 460px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		max-width: 360px;
		height: 280px;
		margin-top: -20px;
	}

	// Smaller Screen
	@media (max-width: 400px) {
		max-width: 262px;
		margin-top: 0;
	}

	// ##################################################

	&__init {
		width: 100%;
		height: 100%;
		visibility: hidden;
		margin: 0 auto;
	}

	// ##################################################

	&__slide {
		user-select: none;
		font-size: 33px;
		line-height: normal;
		font-weight: 700;
		color: var(--color-sky-blue);
	}

	// ##################################################

	&__caption {
		margin-top: 44px;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: visibility 0.3s ease, opacity 0.3s ease;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 50px;
		}
	}

	// ##################################################

	&__image {
		max-width: 264px;
		width: 100%;
		height: 378px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 370px;
			max-width: 290px;
		}

		@media (max-width: 400px) {
			height: 300px;
			max-width: 220px;
		}

		// ##################################################

		img {
			width: 100%;
			height: 124px;
			object-fit: contain;
			transition: all 0.5s ease;
		}
	}

	// ##################################################

	.swiper-button-prev {
		// Mobile
		@media #{$breakpoint-xs-only} {
			left: 0;
		}
	}

	// ##################################################

	.swiper-button-prev,
	.swiper-button-next {
		// Mobile
		@media #{$breakpoint-xs-only} {
			top: 30%;
		}
	}

	// ##################################################

	.swiper-button-prev {
		// Smaller Screen
		@media (max-width: 400px) {
			left: -20px;
		}
	}

	.swiper-button-next {
		// Smaller Screen
		@media (max-width: 400px) {
			right: -20px;
		}
	}

	// ##################################################

	&__carousel {
		position: relative;
		height: 100%;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			left: 44%;
			transform: translate(-50%, 0) scale(0.8);
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			left: 32%;
			transform: translate(-50%, 0) scale(0.7);
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			left: 23%;
			transform: translate(-50%, 0) scale(0.6);
		}

		// Smaller Screen
		@media (max-width: 400px) {
			left: 4%;
			transform: translate(-50%, 0) scale(0.65);
		}

		// ##################################################

		&-item {
			display: block;
			position: absolute;
			width: 260px;
			height: 456px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: 410px;
				width: 260px;
			}

			@media (max-width: 400px) {
				width: 220px;
			}

			&.active {
				.device-slider__caption {
					opacity: 1;
					visibility: visible;
				}

				// ##################################################

				.device-slider__image img {
					height: 100%;
				}
			}

			&.transition {
				transition: all 0.35s ease;
			}
		}
	}
}
