//**********************************************************
// EDITOR TEXT
//**********************************************************

@import '../../../scss/import';

.editor-text {
	.container {
		.container & {
			max-width: 100%;
			padding: 0;
		}
	}

	// ##################################################
	// Headings

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 700;
		margin-bottom: 30px;

		// Margin above the headings
		* + & {
			margin-top: 40px;
		}

		// If heading is first child, then no top margin!
		&:first-child {
			margin-top: 0;
		}
	}

	// ##################################################

	// H1 Title
	h1 {
		@extend %title_h1;
	}

	// ##################################################

	// H2 Title
	h2 {
		@extend %title_h2;
	}

	// ##################################################

	// H3 Title
	h3 {
		@extend %title_h3;
	}

	// ##################################################

	// H4 Title
	h4 {
		@extend %title_h4;
	}

	// ##################################################

	// H5 Title
	h5 {
		@extend %title_h5;
	}

	// ##################################################

	// H6 Title
	h6 {
		@extend %title_h6;
	}

	// ##################################################
	// General text elements

	p,
	ul,
	ol,
	img,
	figure,
	table {
		margin-top: 1.5em;
		margin-bottom: 1.5em;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		a {
			text-decoration: underline;
		}
	}

	// ##################################################
	// List styles

	ul,
	ol {
		list-style: none;
		display: grid;
		grid-gap: 2px;

		li {
			position: relative;
			padding-left: 40px;
		}
	}

	ul {
		li:before {
			border-radius: 100%;
			width: 6px;
			height: 6px;
			background-color: currentColor;
			color: inherit;
			top: calc(0.5em + 4px);
			@extend %content;
		}
	}

	ol {
		counter-reset: ol_text_counter;

		li {
			counter-increment: ol_text_counter;

			&:before {
				content: counter(ol_text_counter);
				font-weight: 700;
				font-size: inherit;
				position: absolute;
				left: 8px;
				top: 0;
				@extend %inherit_textstyles;
			}
		}
	}

	// ##################################################
	// Tables

	table {
		border: 1px solid;
		width: 100%;

		tr {
			border-bottom: 1px solid;
		}

		th,
		td {
			padding: 0.5em;

			&:not(:first-child) {
				border-left: 1px solid;
			}
		}
	}

	// ##################################################
	// Blockquote

	blockquote {
		p {
			margin: 0 !important;
		}
	}

	// ##################################################
}
