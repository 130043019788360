//**********************************************************
// Intro screen
//**********************************************************

@import '../../../scss/import';

.intro-screen {
	position: relative;
	padding: 70px 0 26px;
	min-height: calc(100vh - 60px);

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 4vh 0 2vh;
		min-height: calc(100vh - 140px);
	}

	// ##################################################

	&__header {
		text-transform: uppercase;
		margin-bottom: 54px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 4vh;
		}

		// ##################################################

		&-super-title {
			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 3.5vh;
			}
		}

		// ##################################################

		.title--h1 {
			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 5.5vh;
			}
		}
	}

	// ##################################################

	strong {
		font-weight: 700;
	}

	// ##################################################

	&__body {
		// ##################################################

		.container {
			max-width: 376px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: calc(100% - 40px);
			}
		}

		// ##################################################

		&-item {
			margin-bottom: 56px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-bottom: 3vh;
			}

			// ##################################################

			&--birth-date {
				// Tablet L and up
				@media #{$breakpoint-sm-up} {
					margin-bottom: 30px;
				}
			}

			// ##################################################

			&--radio-button {
				margin-bottom: 20px;
			}
		}
	}

	// ##################################################

	&__title {
		margin-bottom: 6px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 2.2vh;
			margin-bottom: 0;
		}
	}

	// ##################################################

	.input-field__label {
		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 2.5vh;
		}
	}

	// ##################################################

	.birth-date {
		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0 0 1.7vh;
		}

		.input-field__input {
			// Mobile
			@media #{$breakpoint-xs-only} {
				height: 4.4vh;
				font-size: 2.4vh;
			}
		}
	}

	// ##################################################

	.radiobox-inline .radio-button:not(:last-child) {
		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-right: 5vw;
		}
	}

	// ##################################################

	&__button {
		margin-top: 38px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 4vh;

			.btn {
				font-size: 3.6vh;
				min-height: 6vh;
			}
		}
	}
}
