// **************************************************
// Code Input
// **************************************************

@import '../../../scss/import';

.code-input {
	width: 100%;
	position: relative;
	margin: 0 auto;
	max-width: 600px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		max-width: 100%;
	}

	// ##################################################

	&__row {
		display: grid;
		grid-gap: 16px;
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}

	// ##################################################

	.input-field {
		min-width: 1px;
		width: 100%;
		margin: 0;
		@include placeholder(rgba($cgp_blue, 0.27));

		// ##################################################

		&__input {
			width: 100%;
			padding: 0;
			text-align: center;
			height: 64px;
			box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
			font-size: 28px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: 8vh;
				font-size: 3.6vh;
			}
		}
	}

	// ##################################################

	&__validation-message {
		display: none;
		color: var(--color-red);
		text-align: right;
	}
}
