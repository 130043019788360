//**********************************************************
// Selection Screen
//**********************************************************

@import '../../../scss/import';

.selection-screen {
	position: relative;
	padding: 80px 0 30px;
	min-height: calc(100vh - 60px);
	overflow: hidden;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	animation: animate-selection-screen 1.2s cubic-bezier(0, 0, 0, 0.98) forwards;

	// Tablet P and down
	@media #{$breakpoint-md-down} {
		padding: 60px 0;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 4vh 0 2vh;
		min-height: calc(100vh - 140px);
		align-items: flex-start;
	}

	// ##################################################

	&__header {
		max-width: 600px;
		margin-bottom: 70px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0 auto 24px;
			max-width: 280px;
		}

		// ##################################################

		.title {
			margin-bottom: 8px;
		}
	}

	// ##################################################

	&__body {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 59% 1fr;

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-template-columns: 1fr;
			grid-gap: 0;
		}

		// ##################################################

		&-right {
			display: flex;
			align-items: center;
			flex-direction: column;
			text-align: center;
			max-width: 290px;
			padding: 8px 0;

			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: 100%;
				padding: 0;
				margin-top: 60px;
			}

			// ##################################################

			&-inner {
				// Mobile
				@media #{$breakpoint-xs-only} {
					width: 100%;
					position: relative;
					text-align: left;
					padding-left: 100px;
				}
			}
		}

		// ##################################################

		&-text {
			color: var(--color-blue);
			line-height: 1.2;
			font-weight: 300;

			.text--24 {
				// Mobile
				@media #{$breakpoint-xs-only} {
					font-size: 3.6vh;
				}
			}

			&-2 {
				margin-top: 10px;
			}
		}

		// ##################################################

		&-title {
			font-size: 29px;
			font-weight: 700;
			line-height: normal;
			color: var(--color-blue);
			margin: 8px 0;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 26px;
			}
		}
	}

	// ##################################################

	&__key-image {
		display: inline-block;
		max-width: 100px;
		margin: 20px 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0;
			position: absolute;
			top: 0;
			left: 30px;
			top: 0;
			max-width: 44px;
		}
	}

	// ##################################################

	&__key-title {
		font-size: 33px;
		font-weight: 700;
		line-height: 0.76;
		color: var(--color-sky-blue);

		// Mobile
		@media #{$breakpoint-xs-only} {
			display: none;
		}
	}

	// ##################################################

	&__button {
		margin-top: 10px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 4vh;
		}

		.btn {
			padding: 4px 16px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 22px;
				min-height: 50px;
				padding: 5px 20px;
			}
		}
	}

	// ##################################################

	&__loader {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		z-index: 9;
		min-height: 850px;
		perspective: 820px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 100vh;
			min-height: 700px;
			perspective: 720px;
		}

		@media (max-width: 767px) and (orientation: landscape) {
			height: 100%;
			min-height: 400px;
		}

		// ##################################################

		&-text {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			animation: animate-outer-circle 1.2s cubic-bezier(0, 0, 0, 0.98) forwards;
		}

		// ##################################################

		&-inner {
			transform-origin: 50% 50%;
			animation: animate-inner-circle 1.2s cubic-bezier(0, 0, 0, 0.98) forwards;

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				animation: animate-inner-circle-tablet-l 1.2s cubic-bezier(0, 0, 0, 0.98) forwards;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				animation: animate-inner-circle-tablet 1.2s cubic-bezier(0, 0, 0, 0.98) forwards;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				position: absolute;
				left: 50%;
				top: 0;
				width: 340px;
				animation: animate-inner-circle-mobile 1.2s cubic-bezier(0, 0, 0, 0.98) forwards;
			}
		}
		// ##################################################

		&-outer {
			width: 1460px;
			height: 1460px;
			left: 52%;
			top: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
			animation: animate-outer-circle 1.2s cubic-bezier(0, 0, 0, 0.98) forwards;

			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 900px;
				height: 900px;
				left: 42%;
				top: 44%;
			}
		}

		.active-path {
			transition: all 0.3s ease;
			fill: var(--active-color, #008bd2);
		}

		svg {
			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: 100%;
				height: auto;
			}
		}
	}
}

@keyframes animate-outer-circle {
	0% {
		opacity: 1;
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes animate-inner-circle {
	0% {
		transform: translate(0, 0) rotateX(0) rotateZ(0);
	}

	80% {
		transform: translate(0, 0) rotateX(0) rotateZ(0);
	}

	100% {
		transform: translate(-40%, 16%) rotateX(77deg) rotateY(7deg) rotateZ(68deg);
	}
}

@keyframes animate-inner-circle-tablet-l {
	0% {
		transform: translate(0, 0) rotateX(0) rotateZ(0);
	}

	80% {
		transform: translate(0, 0) rotateX(0) rotateZ(0);
	}

	100% {
		transform: translate(-35%, 13%) rotateX(77deg) rotateY(7deg) rotateZ(68deg) scale(0.8);
	}
}

@keyframes animate-inner-circle-tablet {
	0% {
		transform: translate(0, 0) rotateX(0) rotateZ(0);
	}

	80% {
		transform: translate(0, 0) rotateX(0) rotateZ(0);
	}

	100% {
		transform: translate(-28%, 7%) rotateX(77deg) rotateY(7deg) rotateZ(68deg) scale(0.8);
	}
}

@keyframes animate-inner-circle-mobile {
	0% {
		top: 50%;
		transform: translate(-50%, -50%) rotateX(0) rotateZ(0);
	}

	80% {
		top: 50%;
		transform: translate(-50%, -50%) rotateX(0) rotateZ(0);
	}

	100% {
		top: 0;
		transform: translate(-50%, 186px) rotateX(78deg) rotateY(2deg) rotateZ(74deg);
	}
}

@keyframes animate-selection-screen {
	0% {
		opacity: 0;
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
